import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"
import Button from "../components/common/Button"
import { Helmet } from "react-helmet"

const Wrapper = styled.section`
  max-width: 800px;
  padding: 10px;
  margin: 0 auto;
  min-height: calc(100vh - 75px - 280px);

  h1 {
    font-size: 8em;
    margin: 0;
  }

  .custom-button {
    width: 100%;
    margin: 10px 10px 10px 0;
    
    @media (min-width: 650px) {
      max-width: 300px;
    }
  }

  .button-wrapper {
    display: flex;
    flex-wrap: wrap;
  }
`

const NotFoundPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>404 Page not found - Dieser Weg führt ins Leere...</title>
      <meta name="description" content="404 Page not found - Dieser Weg führt ins Leere..." />
    </Helmet>
    <Layout>
      <Wrapper>
        <h1>404</h1>
        <h2>Dieser Weg führt ins Leere...</h2>
        <p>
          Doch keine Sorge von unserer Homepage aus werden Sie sich gut zurecht
          finden.
        </p>
        <div className="button-wrapper">
          <Button className="custom-button">
            <Link to="/">Zurück zur Hompage</Link>
          </Button>
          <Button className="custom-button" secondary>
            <Link to="/hallo">Kontaktieren Sie Uns</Link>
          </Button>
        </div>
      </Wrapper>
    </Layout>
  </>
)

export default NotFoundPage
